<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">{{ $t("Add") }}  {{ $t('New') }} User</h5>

        <i class="fas fa-times ml-1 cursor-pointer" @click="hide"></i>
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="nope"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Full Name"
            rules="required"
          >
            <b-form-group :label="$t('Form.Name')" label-for="full-name">
              <b-form-input
                id="full-name"
                v-model="userData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="John Doe"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="Username"
            rules="required|alpha-num"
          >
            <b-form-group label="Lastname" label-for="username">
              <b-form-input
                id="username"
                v-model="userData.lastname"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group label="Email" label-for="email">
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                trim
                autocomplete="off"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Company -->
          <validation-provider
            #default="validationContext"
            name="Phone"
            rules="required"
          >
            <b-form-group :label="$t('Form.Phone')" label-for="contact">
              <b-form-input
                id="contact"
                v-model="formatPhone"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Role"
            rules="required"
          >
            <b-form-group
              label="Roles"
              label-for="role"
            >
              <treeselect
                id="status"
                v-model="userData.roles"
                :state="getValidationState(validationContext)"
                multiple
                :options="rolesOptions"
                :normalizer="normalizer"
              />
              <b-form-invalid-feedback
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Date of Birth -->
          <validation-provider
            #default="validationContext"
            name="Date of birth"
            rules="required"
          >
            <b-form-group label="Date of birth" label-for="date-birth">
              <flat-pickr
                v-model="userData.date_birth"
                :config="config"
                class="form-control"
                :state="getValidationState(validationContext)"
                placeholder="MM-DD-YYYY"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Zip Code -->
          <validation-provider
            #default="validationContext"
            name="Zip Code"
            rules="required"
          >
            <b-form-group
              label="Zip Code"
              label-for="zip-code"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                v-model.number="userData.zip_code"
                input-id="zip-code"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Password -->
          <validation-provider
            #default="validationContext"
            name="Password"
            rules="required"
          >
            <b-form-group label="Password" label-for="password">
              <b-form-input
                id="password"
                v-model="userData.password"
                autocomplete="new-password"
                :state="getValidationState(validationContext)"
                trim
                type="password"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Repeat password"
            rules="required"
          >
            <b-form-group label="Repeat password" label-for="passwordRepeat">
              <b-form-input
                id="passwordRepeat"
                v-model="userData.passwordRepeat"
                :state="getValidationState(validationContext)"
                trim
                type="password"
              />
              <small class="text-danger" v-if="!checkPassword"
                ><span>passwords do not match</span></small
              >
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="loading || !checkPassword"
            >
              <span v-if="!loading">{{ $t("Add") }}</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { computed, ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@/core/utils/forms/form-validation";

import flatPickr from "vue-flatpickr-component";
import SpinnerLoading from "@/components/SpinnerLoading";

/* import Cleave from "vue-cleave-component";
import CleavePhone from "cleave.js/src/addons/phone-type-formatter.i18n";
require("cleave.js/dist/addons/cleave-phone.us"); */

import axiosU from "@/core/services/apiInsurance/admin/user";
import axiosR from "@/core/services/apiInsurance/admin/roles";

export default {
  components: {
    flatPickr,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    SpinnerLoading,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
    };
  },
  setup(props, { emit }) {
    const blankUserData = {
      name: "",
      lastname: "",
      email: "",
      roles: [],
      currentPlan: null,
      company: "",
      date_birth: new Date(),
      country: "",
      cities_id: 1211,
      phone: "",
      password: "",
      zip_code: "",
    };

    const formatPhone = computed({
      get() {
        let { phone } = userData.value;
        phone = phone.toString().replace(/\D/g, "");
        const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
        if (match) {
          phone = `(${match[1]}${match[2] ? "" : ""}) ${match[2]}${
            match[3] ? "-" : ""
          }${match[3]}`;
        }
        return phone;
      },
      set(val) {
        userData.value.phone = val;
      },
    });

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const rolesOptions = ref([]);
    const getRoles = () => {
      axiosR.rolesList().then((res) => {
        rolesOptions.value = res.data;
      });
    };
    getRoles();

    const checkPassword = computed(() => {
      if (userData.value.password !== userData.value.passwordRepeat) {
        return false;
      }
      return true;
    });
    const loading = ref(false);
    const onSubmit = () => {
      loading.value = true;
      const phone = userData.value.phone.replace(/[() -]/g, "");
      axiosU
        .userCreate({ ...userData.value, phone })
        .then(() => {
          loading.value = false;
          resetuserData();
          emit("createUser", true);
        })
        .catch(() => {
          loading.value = false;
        });
      /* store.dispatch('app-user/addUser', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        }) */
    };
    const config = {
      altInput: true,
      altFormat: "m-d-Y",
      dateFormat: "m-d-Y",
      allowInput: true,
      static: true,
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    return {
      formatPhone,
      config,
      loading,
      userData,
      onSubmit,
      rolesOptions,
      checkPassword,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
