import { userAxios } from '../index'

/**
 * It returns a promise that resolves to the data property of the response object returned by the axios
 * request.
 * @param data - {
 * @returns The data object is being returned.
 */
const rolesList = async data => {
  try {
    return await userAxios.get('authorizations/roles', { data }).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

/**
 * This function will return the data from the API call, or if there's an error, it will log the error
 * to the console.
 * @param id - the id of the permission
 * @returns The return value of the async function is a promise.
 */
const permissionsDetail = async id => {
  try {
    return await userAxios.get(`insurance/roles/${id}`).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const assignPermission = async data => {
  try {
    return await userAxios
      .post(`insurance/authorizations/permissionsToRole`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export default {
  rolesList,
  permissionsDetail,
  assignPermission,
}
